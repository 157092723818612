import axios from "axios";
import { API_BASE_URL } from "../constants/ApiConstant";

export const ApiService = () => {
  axios.defaults.baseURL = API_BASE_URL;
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.headers.common["Content-Type"]= "multipart/form-data";
  axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem('token')}`;
  axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/auth/login";
      }
      return Promise.reject(error);
    }
  );
};
