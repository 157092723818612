import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from 'react-redux'
import { signOut } from 'redux/actions/Auth';
import { useHistory } from "react-router-dom";
import { UserOutlined } from '@ant-design/icons';

export const NavProfile = ({signOut}) => {
  let history = useHistory();
  const profileIcon = "/img/svg/Profile.svg"

  const viewProfile = () => {
		history.push(`/app/dashboard/my-profile`)
	}

  const profileMenu = (
    <div className="nav-profile nav-dropdown mt-4">
      <div className="nav-profile-header">
        <div className="d-flex">
          {/* <Avatar size={45} src={"/img/avatars/thumb-2.jpg"} /> */}
          <UserOutlined style={{fontSize: '34px'}} />
          <div className="pl-3">
            <h4 className="mb-0">{localStorage.getItem('name')}</h4>
            <span className="text-muted">{localStorage.getItem('email')}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={'profile'} onClick={viewProfile} style={{backgroundColor: '#ffffff', color: '#424143'}}>
              <img src="/img/svg/profile-dropdown/profile.svg" alt="profile" />
              <span className="font-weight-normal ml-3">My Profile</span>
          </Menu.Item> 
          <Menu.Item key={'logout'} onClick={e => {
            localStorage.clear();
            window.location.reload()
          }} style={{backgroundColor: '#ffffff', color: '#424143'}}>
            <span>
              <img src="/img/svg/profile-dropdown/logout.svg" alt="logout" />
              <span className="font-weight-normal ml-3">Log out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["hover"]}>
      <span><img size={40} src={profileIcon} alt="icon"/></span>
    </Dropdown>
  );
}

export default connect(null, {signOut})(NavProfile)
