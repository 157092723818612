import {
  HomeFilled, UserAddOutlined, UsergroupAddOutlined, UserOutlined, BankOutlined, FileDoneOutlined, CarOutlined, FundProjectionScreenOutlined
} from '@ant-design/icons';

import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [
  {
    key: "d",
    // path: `${APP_PREFIX_PATH}/dashboard`,
    path: "",
    title: " ",
    icon: "",
    breadcrumb: false,
    submenu: [
      {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        title: 'Dashboard',
        icon: HomeFilled,
        imgSrc: `/img/svg/menu-icons/Home-Dashboard.svg`,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'userManagement',
        path: `${APP_PREFIX_PATH}/user-management`,
        title: 'User Management',
        icon: UserAddOutlined,
        imgSrc: `/img/svg/menu-icons/User-management.svg`,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'customerManagement',
        path: `${APP_PREFIX_PATH}/customer-management`,
        title: 'Customer Management',
        icon: UserOutlined,
        imgSrc: `/img/svg/menu-icons/Customer-management.svg`,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'serviceManagement',
        path: `${APP_PREFIX_PATH}/service-management`,
        title: 'Service Management',
        icon: UsergroupAddOutlined,
        imgSrc: `/img/svg/menu-icons/Service-management.svg`,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'loanManagement',
        path: `${APP_PREFIX_PATH}/loan-management`,
        title: 'Loan Management',
        icon: BankOutlined,
        imgSrc: `/img/svg/menu-icons/Loan-management.svg`,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'inventory',
        path: `${APP_PREFIX_PATH}/inventory`,
        title: 'Inventory',
        icon: FileDoneOutlined,
        imgSrc: `/img/svg/menu-icons/Inventory.svg`,
        // breadcrumb: false,
        submenu: [
          {
            key: 'inventory-items',
            path: `${APP_PREFIX_PATH}/inventory/items`,
            title: 'All Part',
            // icon: FileDoneOutlined,
            imgSrc: `/img/svg/menu-icons/Items.svg`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'inventory-categories',
            path: `${APP_PREFIX_PATH}/inventory/categories`,
            title: 'Part Category',
            // icon: FileDoneOutlined,
            imgSrc: `/img/svg/menu-icons/Categories.svg`,
            breadcrumb: true,
            submenu: []
          },
          {
            key: 'inventory-warehouses',
            path: `${APP_PREFIX_PATH}/inventory/warehouses`,
            title: 'Warehouses',
            // icon: FileDoneOutlined,
            imgSrc: `/img/svg/menu-icons/Warehouses.svg`,
            breadcrumb: true,
            submenu: []
          }
        ]
      },
      {
        key: 'masterData',
        path: `${APP_PREFIX_PATH}/master-data`,
        title: 'Master Data',
        icon: FundProjectionScreenOutlined,
        imgSrc: `/img/svg/menu-icons/Master-data.svg`,
        breadcrumb: false,
        submenu: [
          {
            path: `${APP_PREFIX_PATH}/master-data`,
            title: 'Master Data',
            // icon: FundProjectionScreenOutlined,
            imgSrc: `/img/svg/menu-icons/Master-data.svg`,
            breadcrumb: false,
            submenu: []
          },
          {
            path: `${APP_PREFIX_PATH}/master-data/loan-agreement`,
            title: 'Loan Agreement',
            // icon: FundProjectionScreenOutlined,
            imgSrc: `/img/svg/menu-icons/Master-data.svg`,
            breadcrumb: false,
            submenu: []
          },
          {
            path: `${APP_PREFIX_PATH}/master-data/bike-catalogue`,
            title: 'Bike Sale Catalogue',
            // icon: FundProjectionScreenOutlined,
            imgSrc: `/img/svg/menu-icons/Master-data.svg`,
            breadcrumb: false,
            submenu: []
          }
        ]
      },
      // {
      //   key: 'serviceFeedback',
      //   path: `${APP_PREFIX_PATH}/service-feedback`,
      //   title: 'Service Feedback',
      //   icon: FileDoneOutlined,
      //   imgSrc: `/img/svg/menu-icons/Service-feedback.svg`,
      //   breadcrumb: false,
      //   submenu: []
      // }
    ],

  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
