import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { ApiService } from "../../utils/Api";
export const AppViews = () => {
  ApiService();
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/user-management`} component={lazy(() => import(`./user-management`))} />
        <Route path={`${APP_PREFIX_PATH}/customer-management`} component={lazy(() => import(`./customer-management`))} />
        <Route path={`${APP_PREFIX_PATH}/service-management`} component={lazy(() => import(`./service-management`))} />
        <Route path={`${APP_PREFIX_PATH}/loan-management`} component={lazy(() => import(`./loan-management`))} />
        <Route path={`${APP_PREFIX_PATH}/inventory`} component={lazy(() => import(`./inventory/AllRoute`))} />
        <Route path={`${APP_PREFIX_PATH}/rental-vehicle-management`} component={lazy(() => import(`./rental-vehicle-management`))} />
        <Route path={`${APP_PREFIX_PATH}/master-data`} component={lazy(() => import(`./master-data`))} />
        <Route path={`${APP_PREFIX_PATH}/service-feedback`} component={lazy(() => import(`./service-feedback`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);