import React, { Component } from 'react';
import { Menu } from 'antd';
import { connect } from "react-redux";
import NavProfile from './NavProfile';

export class NavPanel extends Component {
	
	render() {
		return (
      <>
        <Menu mode="horizontal">
          {/* <Menu.Item key="panel" >
            <span><img size={20} src={"/img/svg/Setting.svg"} alt="icon"/></span>
          </Menu.Item> */}
          <Menu.Item key="profile" >
            <NavProfile />
          </Menu.Item>
        </Menu>
      </>
    );
	}
}

const mapStateToProps = ({ theme }) => {
  const { locale } =  theme;
  return { locale }
};

export default connect(mapStateToProps)(NavPanel);